@font-face
  font-family: "Brandon Grotesque"
  src: url("fonts/Brandon_Grotesque/BrandonGrotesque_Light.eot?#iefix")
  src: url("fonts/Brandon_Grotesque/BrandonGrotesque_Light.eot?#iefix") format("eot"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Light.woff2") format("woff2"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Light.woff") format("woff"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Light.ttf") format("truetype")
  font-weight: 300
  font-style: normal

@font-face
  font-family: "Brandon Grotesque"
  src: url("fonts/Brandon_Grotesque/BrandonGrotesque_Regular.eot?#iefix")
  src: url("fonts/Brandon_Grotesque/BrandonGrotesque_Regular.eot?#iefix") format("eot"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Regular.woff2") format("woff2"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Regular.woff") format("woff"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Regular.ttf") format("truetype")
  font-weight: normal
  font-style: normal

@font-face
  font-family: "Brandon Grotesque"
  src: url("fonts/Brandon_Grotesque/BrandonGrotesque_Medium.eot?#iefix")
  src: url("fonts/Brandon_Grotesque/BrandonGrotesque_Medium.eot?#iefix") format("eot"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Medium.woff2") format("woff2"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Medium.woff") format("woff"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Medium.ttf") format("truetype")
  font-weight: 500
  font-style: normal

@font-face
  font-family: "Brandon Grotesque"
  src: url("fonts/Brandon_Grotesque/f1f5ef15_7cd7_406b_9b36_3a035f6d3867.eot?#iefix")
  src: url("fonts/Brandon_Grotesque/f1f5ef15_7cd7_406b_9b36_3a035f6d3867.eot?#iefix") format("eot"),url("fonts/Brandon_Grotesque/bdbe3c16_f5d8_4a1f_a01f_8f48fa14fc9a.woff2") format("woff2"),url("fonts/Brandon_Grotesque/fc5042b0_916c_4139_acb2_76174ce32515.woff") format("woff"),url("fonts/Brandon_Grotesque/1cbb4b86_726c_4513_ba1e_b86844a3ca19.ttf") format("truetype")
  font-weight: bold
  font-style: normal

@font-face
  font-family: "Brandon Grotesque"
  src: url("fonts/Brandon_Grotesque/BrandonGrotesque_Black.eot?#iefix")
  src: url("fonts/Brandon_Grotesque/BrandonGrotesque_Black.eot?#iefix") format("eot"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Black.woff2") format("woff2"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Black.woff") format("woff"),url("fonts/Brandon_Grotesque/BrandonGrotesque_Black.ttf") format("truetype")
  font-weight: 900
  font-style: normal
