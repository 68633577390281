// Place all the styles related to the assist/assist controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
$assist-panel-border-color: #ddd

.assist-panel
  border-color: darken($assist-panel-border-color, 10%)
  color: $text-color


.assist-panel-default
  .panel-heading
    background-color: darken($privva-lighter-gray, 10%)
    color: #FFF

.assist-panel-primary
  .panel-heading
    background-color: $btn-primary-bg
    color: #FFF

.assist-panel-info
  .panel-heading
    background-color: $btn-info-bg
    color: #FFF


.sheet-uploader-container
  display: inline-block
  margin-bottom: 8px
  background-image: url("assets/images/transparent-tile.jpg")

.sheet-uploader-thumbnail
  height: 25px !important

