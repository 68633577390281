@import ../utils/variables
//@import "~bootstrap-sass/assets/stylesheets/_bootstrap"

// Core variables and mixins
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_mixins.scss"

// Reset and dependencies
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_normalize.scss"

// Core CSS
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_scaffolding.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_type.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_code.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_tables.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_forms.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_buttons.scss"

// Components
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_component-animations.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_input-groups.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_pagination.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_alerts.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_panels.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_close.scss"

// Utility classes
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/_responsive-utilities.scss"

html
  font-size: 16px

// Make the footer appear at the bottom of the screen even when page isn't long enough to scroll
body
  min-height: 100vh
  padding-top: 55px !important

h6
  @include small-heading

#footer-spacer
  clear: both
  height: 120px

#footer
  position: absolute
  bottom: 0
  color: #ccc
  background: #333
  border-top: 5px solid #111
  padding: 16px 0px 17px
  @media (max-width: 768px)
    width: 100%

.footer-navbar
  a
    color: #ddd
  a:hover
    color: #eee
    text-decoration: none

.footer-menu
  @media (min-width: $screen-sm-min)
    margin-bottom: 0

.footer-copyright-version-text
  text-align: left
  @media (min-width: $screen-sm-min)
    text-align: right

.btn.selected
  @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125))

.table > tbody > tr > td.borderless
  border-top: none

// textarea.form-control
//   height: $input-height-base

//narrow down navbar padding so it does not create 2 rows
@media (min-width: 768px)
  .nav
    > li
      > a
        padding: 15px 9px

@media (min-width: 992px)
  .nav
    > li
      > a
        padding: 15px 13px

.page-header
  border-bottom: none
  padding-bottom: 0
  h1
    margin: 0
    font-family: $font-family-sans-serif
    font-size: 36px
    font-weight: normal
    color: #000
  .sub-title
    margin-top: 10px
    color: #999

.input-group.full-width
  .input-group-btn:last-child > .btn
    margin-left: 5px
    border-bottom-left-radius: 4px
    border-top-left-radius: 4px
  .form-control:first-child
    border-bottom-right-radius: 4px
    border-top-right-radius: 4px

.text-reset
  @include reset-text
