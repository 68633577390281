.organization-logo-table {
  min-width: 290px !important;
  width: 50% !important;

  .single-image-container {
    display: inline-block;
    margin-bottom: 8px;
    background-image: url('assets/images/transparent-tile.jpg');
  }

  .single-image-thumbnail {
    height: 50px !important;
  }
}
