.auth-card
  background: #FFF
  border-radius: 4px
  box-shadow: 0px 5px 15px 0px rgba(27,30,35,0.05)
  width: 560px
  margin: 30px auto 0
  max-width: 100%
  padding: 30px 15px

  &__title
    margin-top: 0
    margin-bottom: 30px
    font-size: 20px

  &__links
    margin-top: 20px

    i.fa
      width: 20px
      color: #777777

  &__login-button
    margin-top: 25px
    height: 63px

  @media (min-width: $screen-xs-min)
    padding: 50px
    margin-top: 50px

  @media (min-width: $screen-sm-min)
    padding: 50px 100px
    margin-top: 80px
