/* unopned_circle */
.unopened_wrapper {
  position: absolute;
  margin-top: 20px;
  margin-left: 46px;
}

.unopened_wrapper .unopned_circle {
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background-color: #27a5eb;
  z-index: 2;
}

.unopened_wrapper:hover > .unopned_description_wrapper {
  display: block;
}

.unopened_wrapper .unopned_description_wrapper {
  display: none;
  position: absolute;
  margin-top: 26px;
  margin-left: -24px;
}

.unopened_wrapper .unopned_description_wrapper .unopned_description {
  position: absolute;
  color: #fff;
  font-size: 12px;
  text-align: center;

  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8);
  padding: 4px 12px;
  z-index: 999;
}

.unopened_wrapper .unopned_description_wrapper .unopned_description:before {
  border: solid transparent;
  border-top-width: 0;
  content: '';
  display: block;
  position: absolute;
  width: 0;
  left: 50%;
  top: -5px;
  margin-left: -5px;
  height: 0;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
  z-index: 0;
}

/* list */
.notification_list {
  padding: 15px 10px;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
}

.notification_list.unopened {
  background-color: #eeeff4;
}

.notification_list:hover {
  background-color: #f8f9fb;
}

.notification_list:last-child {
  border-bottom: none;
}

.notification_list:after {
  content: '';
  clear: both;
  display: block;
}

.notification_list .notification_list_cover {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.notification_list .list_image {
  float: left;
  width: 30px;
  height: 30px;
  color: #888;
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*background-color: #979797;*/
}

.notification_list .list_text_wrapper {
  float: left;
  width: calc(100% - 60px);
  margin-left: 20px;
}

.notification_list .list_text_wrapper .list_text {
  color: #4f4f4f;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 0;
  height: auto;
  font-weight: normal;
}

.notification_list .list_text_wrapper .list_text strong {
  font-weight: bold;
}

.notification_list .list_text_wrapper .list_text span {
  color: #979797;
  font-size: 13px;
}
//
//.notification_wrapper {
//  /*margin-left: 20px;*/
//  margin-left: 8px;
//  /*margin-right: 10px;*/
//  margin-right: 8px;
//  padding-top: 15px;
//  float: left;
//  position: relative;
//}
.notification_wrapper .dropdown_notification {
  cursor: pointer;
}

.notification_wrapper a.dropdown_notification:hover {
  text-decoration: none;
}

.notification_count span {
  color: #fff;
  background-color: #909090;
  border-radius: 4px;
  font-size: 12px;
  padding: 4px 8px;
}
.notification_count span.unopened {
  background-color: #777777;
}
.notification_wrapper.open .notification_list_wrapper {
  display: block;
}

.notification_wrapper .notification_list_wrapper {
  //display: none;
  //z-index: 999;
  width: 330px;
  height: 490px;
  border: 1px solid #e5e5e5;
  //position: absolute;
  /*top: calc(100% + 20px);*/
  //top: calc(100% + 5px);
  //right: -10px;
  background-color: #fff;
}
.notification_wrapper .notification_list_wrapper .notification_header_wrapper {
  position: relative;
  width: 329px;
  height: 37px;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  box-sizing: border-box;
  background-color: #fff;
}
.notification_wrapper
  .notification_list_wrapper
  .notification_header_wrapper
  .notification_header_title {
  position: absolute;
  top: 6px;
  left: 10px;
  font-size: 14px;
}
.notification_wrapper
  .notification_list_wrapper
  .notification_header_wrapper
  .notification_header_menu {
  position: absolute;
  top: 6px;
  right: 10px;
  font-size: 14px;
}

.notification_wrapper .notification_list_wrapper .notifications {
  position: relative;
  width: 329px;
  height: calc(500px - 37px - 37px);
  overflow: scroll;
  overflow-x: hidden;
  border-right: 1px solid #e5e5e5;
}
.notification_wrapper .notification_list_wrapper .notification_see_all_link {
  position: absolute;
  bottom: 0;
  width: 329px;
  height: 26px;
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  padding: 2px 0 8px 0;
  text-align: center;
  background-color: #fff;
  z-index: 2;
  text-align: center;
  font-size: 14px;
}
.notification_wrapper
  .notification_list_wrapper
  .notification_see_all_link:hover {
  background-color: #f8f9fb;
}

.notifications a:focus .notification_list {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
