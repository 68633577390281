/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, vendor/assets/stylesheets,
 * or any plugin's vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any styles
 * defined in the other CSS/SCSS files in this directory. It is generally better to create a new
 * file per style scope.
 *
 *= require utils/bootstrap-callouts
 *= require_self
 *= require utils/print
 */
@import utils/bootstrap-callouts
@import utils/print
@import utils/sass-overrides
@import utils/variables
@import utils/mixins
@import ../fonts/Brandon_Grotesque/styles
@import ../fonts/Montserrat/styles

/*
 * This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: https://www.linotype.com
 */

html
  position: relative
  min-height: 100%

body
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

h1
  .subhead
    text-transform: uppercase
    color: #666
    font-size: 50%

.strong
  font-weight: bold

.font-weight-normal
  font-weight: normal

.nobr
  white-space: nowrap

.margin-above
  margin-top: 6px

.margin-below
  margin-bottom: 12px

.margin-left
  margin-left: 6px

.margin-right
  margin-right: 6px

/* App specific CSS */

.comment-flag
  position: relative

.comment-flag:after
  content: ""
  position: absolute
  top: 0
  right: 0
  width: 0
  height: 0
  display: block
  border-left: 10px solid transparent
  border-bottom: 10px solid transparent
  border-top: 10px solid orange

//tr.section:not(.selected), tr.row-group
//  color: #333
//  background: #eee !important
//  td:not(.element-links)
//    font-weight: bold
//    font-size: 120%

tr.section, tr.row-group
  color: #333
  background: #eee !important
  td:not(.element-links)
    font-weight: bold
    font-size: 120%

#template-analyze .h3.question
  color: #AAA
  font-weight: normal
  margin: 20px 0

.prewrap, td.element-label
  white-space: pre-wrap

#template
  td.element-options
    text-align: center

  td.element-links
    padding-top: 6px
    a
      color: #555

  th.key
    text-align: center

.mark-reviewed
  a:hover, a:active
    text-decoration: none

  .label
    text-shadow: 0 0 3px #777

    &:hover
      span
        display: none
    &:hover:before
      content: 'Mark Reviewed'

.alert > .error-message:first-letter
  text-transform: uppercase

.spinner-overlay
  opacity: 0.5
  display: none
  .spin-big
    position: absolute
    left: 50%
    top: 50%
    margin-left: -25px
    margin-top: -25px
    font-size: 50px
    width: 50px
    height: 50px
    color: #DDD

.grey50
  color: #888

.white
  color: white

.disabled
  opacity: 0.5

.center
  text-align: center

.toggle-spacer
  margin-top: 40px

header
  background-color: $header-background-grey
  margin-bottom: 20px

.overall-risk-rating
  font-weight: bold

.risk-rating-cell
  font-size: 18px
  padding-top: 5px !important

.risk-rating-container

  .overall-risk-rating
    line-height: 1.2
    font-weight: bold
    font-size: 64px

.section-risk-rating-label
  text-align: left

.top5
  margin-top: 5px

.top7
  margin-top: 7px

.top10
  margin-top: 10px

.top15
  margin-top: 15px

.top17
  margin-top: 17px

.top30
  margin-top: 30px

.left5
  margin-left: 5px

.left7
  margin-left: 7px

.left8
  margin-left: 7px

.left10
  margin-left: 10px

.left15
  margin-left: 15px

.left17
  margin-left: 17px

.left30
  margin-left: 30px

.float-left
  float: left

.clear-both
  clear: both

.noresize
  resize: none

.vresize
  resize: vertical

.hresize
  resize: horizontal

pre.reset
  /* defaults */
  display: block
  font-family: monospace
  white-space: pre
  margin: 1em 0px
  /* bootstrap overrides */
  padding: initial
  line-height: initial
  color: initial
  background-color: initial
  border: initial
  overflow: initial
  word-break: initial
  word-wrap: initial
  border-radius: initial

.anchor
  padding-top: 50px
  margin-top: -50px
  width: 0px

.monospace
  font-family: monospace

.fixed-error-alert
  width: 90%
  max-width: 400px
  position: fixed
  top: 70px
  right: 20px

/* Responsive Grid and Sidebar */
.grid-container
  display: grid
  grid-template-columns: min-content 1fr
  grid-template-rows: auto 1fr auto
  grid-template-areas: "sidebar header" "sidebar main" "sidebar footer"
  @media (max-width: 768px)
    grid-template-columns: 0px 1fr

.side-nav
  grid-area: sidebar
  display: flex
  flex-direction: column
  justify-content: space-between
  overflow-y: auto
  @media (max-width: 768px)
    display: none

.signed-on-nav
  width: 100px

.main-content
  grid-area: main
  width: calc(100vw - 100px)

.layout-navbar
  grid-area: header

.footer-navbar
  grid-area: footer
  width: 100%

  &.signed-in
    width: calc(100% - 100px)

@media (max-width: 767px)
  .footer-navbar,
  .footer-navbar.signed-in
    width: 100% !important
  .main-content
    width: 100vw !important

// overrides for vendored css
@import overrides/bootstrap
@import overrides/colors
@import overrides/internet-explorer

.wrap-second-line
  padding-left: 1em
  text-indent: -1em

// page specific css
@import pages/assist
@import pages/authentication
@import pages/notifications
@import pages/organization
@import pages/project_edit

// component specific css
@import components/datepicker
@import components/notifications
@import components/rc-slider

.subscription_wrapper
  .ui.checkbox input:checked + .slider:before
    background-color: $brand-primary

.text-larger
  font-size: 125%

.no-lang
  /* no translate language */
.dyn-lang
  /* dynamic elements to translate language for */

.grey-bg
  background-color: #F5F5F5

  header
    background-color: transparent

.recharts-layer
  outline: none

// https://github.com/recharts/recharts/issues/1114#issuecomment-407949274
@media print
  .recharts-responsive-container
    .recharts-wrapper, .recharts-wrapper > .recharts-surface
      width: calc(100%) !important

.expired
  color: $brand-danger

.container
  width: 98%
