.text-very-poor
  color: $color-very-poor
.text-poor
  color: $color-poor
.text-acceptable
  color: $color-acceptable
.text-good
  color: $color-good
.text-very-good
  color: $color-very-good
.text-nothing
  color: $color-nothing

.primary-color
  color: $brand-primary
