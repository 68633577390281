.footer-navbar-print
  display: none

@media print
  *,
  *:before,
  *:after
    box-shadow: none !important
    text-shadow: none !important
    // Force webkit browsers to print background colors
    -webkit-print-color-adjust: exact
    // https://www.lockedowndesign.com/chrome-print-preview-differs-from-dev-tools/
    -webkit-transition: none !important
    transition: none !important

  body
    padding-top: 30px

  // Don't show the URL of links after them in print view
  a[href]:after
    content: none

  #footer
    margin-top: 30px
    background: transparent
    border-top: none

  .footer-navbar
    display: none

  .footer-navbar-print
    display: block
