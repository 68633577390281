//$privva-navbar-bg: <%#= Theming::Colors::PRIMARY_COLOR %>
$privva-navbar-bg: #5394ff
$privva-lighter-gray: #F5F5F5
$header-background-grey: $privva-lighter-gray
$brand-blue: #2176DB
$med-dark-gray: #777

$color-nothing: #666
// todo: convert these to use Privva.Utils.erb?
//$color-very-poor: <%#= Theming::Colors::SCORE_COLORS[-2] %>
//$color-poor: <%#= Theming::Colors::SCORE_COLORS[-1] %>
//$color-acceptable: <%#= Theming::Colors::SCORE_COLORS[0] %>
//$color-good: <%#= Theming::Colors::SCORE_COLORS[1] %>
////$color-very-good: <%#= Theming::Colors::SCORE_COLORS[2] %>
//$color-tier-1: <%= Theming::Colors::TIER_COLORS[1] %>
//$color-tier-2: <%= Theming::Colors::TIER_COLORS[2] %>
//$color-tier-3: <%= Theming::Colors::TIER_COLORS[3] %>
//$color-tier-4: <%= Theming::Colors::TIER_COLORS[4] %>
$color-very-poor: #DD2400
$color-poor: #EB5809
$color-acceptable: #FED260
$color-good: #8CC93D
$color-very-good: #01BC31
$color-tier-1: #DD2400
$color-tier-2: #FC7F27
$color-tier-3: #E4B93F
$color-tier-4: #01BC31

$font-family-secondary: "Montserrat", sans-serif

// Override bootstrap variables
$font-family-sans-serif: "Brandon Grotesque", Helvetica, Arial, sans-serif !default
$font-size-base: 16px
$font-size-h3: 24px
$font-size-h4: 18px
$padding-base-vertical: 7px
$headings-font-family: $font-family-secondary
$headings-font-weight: bold
$btn-font-weight: 500

$brand-primary: var(--primary-color)
$btn-info-bg: darken(#428bca, 6.5%)
$code-color: #333333
$code-bg: #eeeeee


$brand-success: #01BC31
$brand-info: #2176DB
$brand-warning: #E4B93F
$brand-danger: #DD2400
