.col-form-label
	font-size: 12px
	color: #9E9E94
	font-weight: 700
	margin-top: 8px

.page-project-edit
	position: relative

.editable-table-search-input-container
	position: relative

.editable-table-search-drop-down-container
	background-color: #fff
	position: absolute

	top: 36px
	width: 100%
	max-height: 150px
	padding: 10px 10px 0px 10px

	border-radius: 4px
	box-shadow: 0 4px 6px 2px rgba(0, 0, 0, .10)

	overflow-y: scroll
	overflow-x: hidden
	z-index: 1

.project-edit-hr
	margin: 40px 0px

.margin-bottom-20
	margin-bottom: 20px

.project-edit-save-and-close-btn
	position: absolute
	right: 0
	top: -75px

.project-save-fade-out
	visibility: hidden
	opacity: 0
	transition: visibility 0s linear 300ms, opacity 300ms

.project-save-fade-in
	visibility: visible
	opacity: 1
	transition: visibility 0s linear 0s, opacity 300ms

.project-save-show
	visibility: visible
	opacity: 1
