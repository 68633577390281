.datepicker-popper {
  &.react-datepicker-popper {
    min-width: 340px;
    font-weight: normal;
  }

  .react-datepicker__time-container--with-today-button {
    right: -95px;
    width: 95px;
    bottom: 0;
    display: flex;
    flex-direction: column;

    .react-datepicker__time {
      flex: 1;
      overflow: hidden;
    }

    .react-datepicker__time-box {
      width: 100% !important;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .react-datepicker__time-list {
      flex: 1;
    }
  }

  .react-datepicker__monthPicker {
    width: 13.8rem;
  }
}

#datepicker-portal {
  position: absolute;
  top: 0;
  z-index: 1033; // bigger than dropdown menu's and PopoverPanel's z-index
}
